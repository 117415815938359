// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// custom pallete
$cvs-primary: (50 : #fdedea,
  100 : #fad3cb,
  200 : #f7b6a9,
  300 : #f39987,
  400 : #f1836d,
  500 : #ee6d53,
  600 : #ec654c,
  700 : #e95a42,
  800 : #e75039,
  900 : #e23e29,
  A100 : #ffffff,
  A200 : #ffefed,
  A400 : #ffc1ba,
  A700 : #ffaaa1,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

$cvs-secondary: (50 : #e3eaee,
  100 : #b9cbd5,
  200 : #8aa9ba,
  300 : #5b879e,
  400 : #376d89,
  500 : #145374,
  600 : #124c6c,
  700 : #0e4261,
  800 : #0b3957,
  900 : #062944,
  A100 : #7abdff,
  A200 : #47a4ff,
  A400 : #148bff,
  A700 : #007ff9,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  ));

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portfolio2021-primary: mat-palette($cvs-primary);
$portfolio2021-accent: mat-palette($cvs-secondary);

// The warn palette is optional (defaults to red).
$portfolio2021-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portfolio2021-theme: mat-light-theme((color: (primary: $portfolio2021-primary,
      accent: $portfolio2021-accent,
      warn: $portfolio2021-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($portfolio2021-theme);

:root {
  --broken-white-rgb: 246, 245, 245;
  --dark-blue-rgb: 0, 51, 78;

  --broken-white: rgba(var(--broken-white-rgb), 1.0);
  --blue: rgba(20, 83, 116, 1.0);
  --blue-rgb: 20, 83, 116;
  --dark-blue: rgba(var(--dark-blue-rgb), 1.0);
  // --coral: rgba(238, 116, 92, 1.0); // TODO: check out the right color if you want to
  --coral: rgb(238, 109, 83);

  --primary-font-family: 'Montserrat', sans-serif;
  --secondary-font-family: 'Roboto Mono', monospace;

  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;

  --wide-letter-spacing: 0.031875rem;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: var(--broken-white);
}

body::-webkit-scrollbar-thumb {
  background: var(--dark-blue);
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html,
body {
  padding: 0;
  margin: 0
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--broken-white);
  font-size: 1rem;
  font-family: var(--primary-font-family);
  color: var(--dark-blue);
  height: 100%;
  min-height: 100%;
  display: block;

  h1, h2, h3, h4, h5, p, span, a, strong, .subheader, .caption {
    &::selection {
      background-color: var(--coral);
    }
  }


  h1 {
    font-size: 4rem;
    font-weight: var(--font-bold);
    line-height: 3.5rem;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h2 {
    font-size: 2.25rem;
    font-weight: var(--font-regular);
    margin-top: 1rem;

    &+p {
      margin-top: 2.25rem;
    }

    &+div {
      margin-top: 0.5rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: var(--font-regular);
    margin-top: 4rem;

    &+p {
      margin-top: .5rem;
    }
  }

  h5 {
    font-size: 1rem;
    font-weight: var(--font-semi-bold);
    margin-bottom: .5rem;
    margin-top: 3rem;

    &+p {
      margin-top: 0;
    }
  }

  span.caption {
    font-size: .75rem;
    letter-spacing: var(--wide-letter-spacing);
    font-weight: 600;
    margin: .5rem 0;

  }

  p,
  li {
    font-size: 1rem;
    font-weight: var(--font-medium);
    letter-spacing: var(--wide-letter-spacing);
    line-height: 1.5rem;
  }

  p+p {
    margin-top: 1.5rem;
  }

  p+div.banner,
  div.text-wrapper+div.banner,
  a+div.banner {
    margin-top: 2rem;
  }

  a,
  a:visited {
    font-size: 1rem;
    font-weight: var(--font-bold);
    color: var(--coral);
    text-decoration: underline;
    transition: color .3s ease;

    &:hover {
      color: var(--dark-blue);
    }
    &:focus-visible {
      color: var(--dark-blue);
    }
  }

  ul {
    padding-left: 1.5rem;
  }

  h1 strong,
  h2 strong,
  p strong {
    color: var(--coral);
    cursor: pointer;
    transition: color .3s ease;

    &:hover {
      color: var(--dark-blue);
    }
    &:focus-visible {
      color: var(--dark-blue);
    }
  }

  h1 strong,
  h2 strong,
  p strong,
  a {
    &::selection {
      background-color: var(--dark-blue);
      &:hover {
        background-color: var(--coral);
      } 
      &:focus-visible {
        background-color: var(--coral);
      }
    }
  }

  div.intro {
    h2 {
      font-weight: var(--font-bold);
      margin-bottom: 4rem;

      & strong>span {
        font-size: 0.625rem;
        color: var(--dark-blue);
        position: absolute;
        margin-top: 2.625rem;
        margin-left: -9.375rem;
      }
    }

    p {
      font-weight: var(--font-semi-bold);
      line-height: 1.25rem;
      letter-spacing: normal;
    }
  }

  button[mat-button],
  button[mat-menu-item] {
    font-size: 1rem;
    font-weight: var(--font-bold);
    color: var(--dark-blue);
    display: flex;
    transition: background-color .3s ease;
  }

  button[mat-flat-button] {
    text-transform: uppercase;
    background-color: var(--coral);
    color: var(--broken-white);
    font-weight: var(--font-bold);
    font-family: var(--primary-font-family);
    transition: background-color 0.14s ease-in-out;

    &[color=accent] {
      background-color: var(--blue);
    }

    &:hover {
      background-color: var(--blue);
    }
    &:focus-visible {
      background-color: var(--blue);
    }

  }

  div.mat-menu-panel {
    background-color: var(--broken-white);
  }

  img {
    position: relative;
    user-select: none;
  }

  .hex {
    &.coral {
      fill: var(--coral);
    }

    &.blue {
      fill: var(--blue);
    }

    &.dark-blue {
      fill: var(--dark-blue);
    }

    &.broken-white {
      fill: var(--broken-white);
    }
  }

  .mirror {
    transform: scaleX(-1);
  }

  [matTooltip] {
    cursor: pointer;
  }

  .customTooltip {
    color: var(--broken-white);
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 1rem;
    background-color: rgba(var(--blue-rgb), .9);
    margin-top: 0.125rem;
  }
}
